import React, { FC, ReactElement } from "react";
import { RecruiterProps } from "../recruiterData";

interface RecruiterPropsComponent extends RecruiterProps {
  logo: string;
}

const Recruiter: FC<RecruiterPropsComponent> = (
  props: RecruiterPropsComponent
): ReactElement => {
  return (
    <div className="avatar-container">
      <img className="avatar" src={props.img} alt="recruiter" />
      <div className="info-container">
        <div className="name-container">
          <p className="bold">{props.name}</p>
          <a href={props.linkedIn}>
            <img
              src={props.logo}
              alt={`link to ${props.name} linkedin`}
              className="linkedin"
            />
          </a>
        </div>

        <a href={`sms:${props.phone}`} className="mail-link">
          {props.phone}
        </a>
        <a href={`mailto:${props.mail}`} className="mail-link">
          {props.mail}
        </a>
      </div>
    </div>
  );
};

export default Recruiter;
