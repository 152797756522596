import React, { FC, ReactElement } from "react";
import logo from "../assets/Stockholm_blue.png";

const Footer: FC = (): ReactElement => {
  return (
    <div className="footer-container">
      <div className="office-container">
        <div>
          <div className="office-logo-container">
            <img
              src={logo}
              className="office-logo"
              alt="Stockholm office logo"
            />
            <b>Stockholm</b>
          </div>
          <p className="no-margin">Kungsgatan 38</p>
          <p className="no-margin">111 35 Stockholm</p>
          <p className="no-margin">Sweden</p>
        </div>
        <div className="link-container">
          <a className="mail-link" href="https://www.youtube.com/@tretton37ab">
            How we share
            <br /> knowledge
          </a>
          <a className="mail-link" href="https://tretton37.com/">
            tretton37.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
