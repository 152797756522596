import React, { FC, ReactElement } from "react";
import logo from "../assets/tretton37_green.png";
import Footer from "./Footer";
import Recruiters from "./Recruiters";

const Home: FC = (): ReactElement => {
  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="Tretton37 logo" />
      </div>
      <Recruiters />
      <Footer />
    </>
  );
};

export default Home;
