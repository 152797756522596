import React, { FC, ReactElement } from "react";
import linkedIn from "../assets/linkedin.png";
import { RecruiterProps, data } from "../recruiterData";
import Recruiter from "./Recruiter";

const Recruiters: FC = (): ReactElement => {
  return (
    <div className="recruiters">
      {data.map((person: RecruiterProps, index: number) => (
        <Recruiter
          key={index}
          img={person.img}
          logo={linkedIn}
          name={person.name}
          linkedIn={person.linkedIn}
          phone={person.phone}
          mail={person.mail}
        />
      ))}
    </div>
  );
};

export default Recruiters;
