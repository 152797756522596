import sara from "./assets/sara.jpg";
import sandra from "./assets/sandra.png";
import alicia from "./assets/alicia.png";

export interface RecruiterProps {
  name: string;
  phone: string;
  mail: string;
  img: string;
  linkedIn: string;
}

type RecruiterData = Array<RecruiterProps>;

export const data: RecruiterData = [
  {
    name: "Sara Askebäck",
    phone: "+46761337297",
    img: sara,
    mail: "sara.askeback@1337.tech",
    linkedIn: "https://www.linkedin.com/in/saraaskeb%C3%A4ck/",
  },
  {
    name: "Sandra Åberg",
    phone: "+46725381337",
    img: sandra,
    mail: "sandra.aberg@1337.tech",
    linkedIn: "https://www.linkedin.com/in/sandra-%C3%A5berg-b058914b/",
  },
  {
    name: "Alica Sjöberg",
    phone: "+46761337204",
    img: alicia,
    mail: "alicia.sjoberg@1337.tech",
    linkedIn: "https://www.linkedin.com/in/alicia-sj%C3%B6berg-42715014b/",
  },
];
